/*
    JS file structure to follow:
    - Global variables
    - Initialization
    - Events
    - Callback functions
    - General functions

    IMPORTANT! : Initializations and events are to be placed in the $(function() { ... });
    IMPORTANT! : Callback functions and general functions are to be placed outside of the $(function() { ... });

    Don't forget to comment the code in english for better understanding and maintainability of the code. And if possible use explicit variables and function names.
*/

$(function () {
    /*
    Pre header swiper
    **/
    if ($(".promo_swiper .swiper-slide").length > 1) {
        promoSwiper = new Swiper(".promo_swiper", {
            centeredSlides: true,
            slidesPerView: 1,
            autoplay: true,
            speed: 2000,
            loop: true,
        });
    }

    // Fade in effect on homepage
    if ($('body.homepage').length) {
        $('body.homepage').css({ 'opacity': '1' });
    }

    /*
    Add sticky class to header when scroll down
    **/
    "use strict";
    var header = document.getElementById("site_head_wrap");
    var search = document.getElementById("search_home");
    var shade = document.getElementById("shad_menu");

    function onWinScroll() {

        if (header == null) {
            return;
        }

        var scrollTop = $(document).scrollTop();

        if (header.classList.contains("sticky")) {

            if (scrollTop === 0) {
                header.classList.remove("sticky");
                shade.classList.remove("sticky");
            }
        } else {

            if (scrollTop !== 0) {
                header.classList.add("sticky");
                shade.classList.add("sticky");
            }
        }
    }

    function onSearchStateChange(evt) {
        if (evt.type === "focus") {
            search.parentElement.classList.add("searching");
        } else if (search.value.trim() === "") {
            search.parentElement.classList.remove("searching");
        }
    }

    onWinScroll();
    $(window).on("scroll", onWinScroll);
    $(search).on({ focus: onSearchStateChange, blur: onSearchStateChange });

    /*
    Header search
    **/
    $("#trigger_recherche").on("click touch", function () {
        if ($('body').hasClass('search')) {
            closeSearch();
        } else {
            $(".wrap_search_field").fadeIn();
            $("body").addClass("search");
            $("#site_head_wrap").addClass("search_open");
            $("#shad_menu").addClass("actif for_search");
        }
    });

    $(".close_search").on("click", function () {
        let input_search = document.getElementById("search_home");

        if (input_search.value === "") {
            closeSearch();
        } else {
            input_search.value = "";
            input_search.focus();
        }
    });

    $("#shad_menu").on("click", function () {
        $(".wrap_search_field").fadeOut();
        $("body").removeClass("search");
        $('#site_head_wrap').removeClass("search_open");
        $("#trigger_recherche").css("opacity", "1");
        $("#shad_menu").removeClass("actif for_search");
    });

    $("#search_home").on("keypress", function (event) {
        if (event.which === 13) {
            $("#shad_menu").removeClass("actif for_search");
        } else {
            $("#shad_menu").addClass("actif for_search");
        }
    });

    /*
    Loading Pricing
    **/
    if ($("#tarification_load").length > 0) {

        // Chargement des différentes tarification
        $.ajax({
            url: path_relative_root + 'ajax_tarification_list.php',
            type: 'post',
            dataType: 'json',
            success: function (res) {
                if (res !== []) {
                    var div = $('<div>', { 'class': 'custom-select custom-select-footer' });
                    var select = $('<select id="country_tarif" name="country">');
                    $.each(res, function (key, val) {
                        var option = $("<option />", { value: key, text: val.pays_nom });
                        if (val.selected) {
                            option.prop('selected', true);
                        }
                        $(option).appendTo(select);
                    });
                    // Event sur le select lorsque l'on change de pays
                    select.on('change', function () {
                        var country_iso = $(this).val();
                        // On va envoyé au serveur que l'on change de pays
                        $.ajax({
                            url: path_relative_root + 'ajax_tarification_change_country.php',
                            type: 'post',
                            data: 'iso=' + country_iso,
                            success: function () {
                                location.reload();
                            }
                        });
                    });
                    select.appendTo(div);
                    div.appendTo($("#tarification_load"));
                    initCustomSelect('custom-select-footer');
                } else {
                    $("#tarification_load").css('display', 'none');
                }
            }
        });
    }

    /* Load global product swiper initialization on window scroll */
    if ($('.prodItemSlider').length) {
        $(window).on('scroll', function () {
            initItemSlider();
        });
    }

    /*
    Close filters
    */
    $(document).on("click touch", ".js-close-filter", function () {
        $("#rayon_sticky_block").removeClass("show");
        $(".shad").removeClass("actif");
        $("#trigger_filtre").removeClass("triggered");
    });

    /*
    Add class current to the current page on click before redirection
    */
    $('.leftPart .categ_name h2').on('click touch', function () {
        if ($('.leftPart .categ_name').hasClass('current')) {
            $('.leftPart .categ_name').removeClass('current');
        }
        $(this).parent().parent().addClass('current');
    });

    // FP Description Accordion
    fpAccordion();

    //Load only if the page has the class '.in_tunnel'
    if ($('.in_tunnel').length) {
        toggleListProduct();
    }

    // If only one image on page FP
    if ($('.top_page_produit .mainSlideFp .swiper-slide').length === 1) {
        if (!$('body').hasClass('lookbook')) {
            $('.top_page_produit').addClass('onlyOneImage');
        }

    } else {
        $('.top_page_produit').removeClass('onlyOneImage');
    }

    // If there is a single video on product page, switch places with the image in position 2 so that the video is on the right instead
    if (($('.product_page .top_page_produit .mainSlideFp .slide_video').length === 1) && $('#nbr_visu_2').length) {
        $('#nbr_visu_2').addClass('first-position');
    }

    // Remove undesired effect on load
    $('.top_page_produit').css('opacity', '1');
    setTimeout(() => {
        $('#assoc_product_slider').css('opacity', '1');
    }, 210);

    // FP ASSOCIATION SWIPER
    if ($('#assoc_product_slider .item.swiper-slide').length > 4) {
        var assocProductSlider = new Swiper("#assoc_product_slider", {
            slidesPerView: 4.075,
            spaceBetween: 20,
            roundLengths: true,
            freeMode: true,
        });

        // Code updated This one is better and cleaner :)
        function updateSliderTranslate(slideUpdateOnResize) {
            // Get the current window width
            let slideWidth = $('#assoc_product_slider .item.association.swiper-slide').width();
            slideWidth = -(slideWidth / 2);

            if (slideUpdateOnResize === 'resize') {
                let getTranslate = assocProductSlider.getTranslate();
                getTranslate = getTranslate + slideWidth;
                assocProductSlider.translateTo(getTranslate, 200, true, true);

            } else {
                // Translate the slider to the computed value with animation duration 200ms
                assocProductSlider.translateTo(slideWidth, 200, true, true);
            }
        }

        // Initial call to update the slider translate after a 30ms delay
        setTimeout(updateSliderTranslate, 30);

        // Declare a variable to store the resize timer
        let resizeTimer;
        $(window).on('resize', function () {
            // Clear any previously set timer
            clearTimeout(resizeTimer);

            // Set a new timer to update the slider translate after 200ms when the resizing stops
            resizeTimer = setTimeout(() => {
                updateSliderTranslate('resize');
            }, 200);
        });
    }

    // By default show 'sélectionner une taille' btn if no size is chosen 
    noSizeSelected();

    if ($(".swiper_looks .swiper-slide").length > 0) {
        var items = $(".swiper_looks .item_look");
        var items_tmp = [];
        var items_tmp_bloc = [];
        var nb_items = items.length;

        // Display the portraits 2 by 2 and the landscapes 1 by 1
        var i = 0;
        items.each(function (index) {
            var elt = $(this).clone();
            elt.removeClass('swiper-slide');
            // We will "group" the portrait blocks and leave only the landscape blocks, while maintaining the same order
            if (elt.data('format') === 'portrait') {
                items_tmp_bloc.push(elt);
            } else if (elt.data('format') === 'paysage') {
                items_tmp.push(elt);
            }

            // If the number of images per 2 is complete, transfer the array to the final array
            if (items_tmp_bloc.length === 2) {
                items_tmp.push(items_tmp_bloc);
                // Empty the block
                items_tmp_bloc = [];
            }
        });

        $(".swiper_looks .item_look img").each(function (index) {
            var img_width = $(this).width();
            $(this).closest('.item_look').addClass(img_width.toString());
        });

        var swiper_look = $('.swiper_looks .swiper-wrapper');
        // Clear the old swiper
        swiper_look.html('');
        // We will iterate through the grouped images
        $.each(items_tmp, function (index, value) {
            var bloc = $('<div>', { class: 'swiper-slide' }).clone();
            if (value.length > 1) {
                var length = value.length;
                for (var i = 0; i < length; i++) {
                    value[i].appendTo(bloc);
                }
            } else {
                value.appendTo(bloc);
            }
            // Fill with the rearranged images
            bloc.appendTo(swiper_look);
            return '';
        });

        lookSwiper = new Swiper(".swiper_looks", {
            centeredSlides: true,
            slidesPerView: 1.24,
            loop: true,
            loopAdditionalSlides: 0,
            spaceBetween: 10,
            navigation: {
                nextEl: '.swiper-btn.swiper-button-next',
                prevEl: '.swiper-btn.swiper-button-prev',
            }
        });

        lookSwiper.slideTo(2);
    }

    // Change total products wording in the cart
    if ($('.cart.step_1').length) {
        $('.bill_line.total_produit label').html(Translator.translate('my_cart_subtotal'));
    }

    var isMobile = $('body').hasClass('mobile');
    if (!isMobile) {
        changeCookiesConsentDisplay();
    }

/***** PROD ASSOCS DATA LAYERS *****/
    if (window.$('#gondole_portez')) {
        $('#gondole_portez .swiper-slide').on('click', function () {
            var product_id = event.currentTarget.getAttribute('data-swiper-slide-index') ? event.currentTarget.getAttribute('data-swiper-slide-index') : '';

            if (product_id !== '') {
                var productObj = data_layer_products[product_id];
                productObj.url = $(this).children("a").attr("href");
                var prod = productObj;
                delete prod.ean;
                delete prod.ref;
                delete prod.sku;
                delete prod.refSizeColor;
                delete prod.url;
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': { 'list': productObj['category'] },      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': "function () {document.location = productObj.url}"
                });
            }
        });
    }

    $(".return_products_form select").change(function () {
        var selectedElement = $(this);
        checkValidityRequestOfReturn.call(selectedElement);
    });
});


function showPdfEtiquetteRetour(orderId, multiTransp, returnNumber, show_pdf) {

    let data = {};

    if (!show_pdf) {
        data = $('#return_products_form_' + orderId).serialize();
        data += '&idOrder=' + orderId + '&multiTransp=' + multiTransp + '&return_number=' + returnNumber + '&send_mail=true&choix=no_impression';
    } else {
        data = 'idOrder=' + orderId + '&multiTransp=' + multiTransp + '&return_number=' + returnNumber;
    }

    $.ajax({
        url: path_relative_root + 'ajax_create_etiquette.php',
        type: 'post',
        data: data,
        success: function (res) {
            if (res.substr(0, 5) == 'false') {
                alert(Translator.translate('js_error_return_label'));
            } else {

                if (show_pdf) {
                    window.location.href = res;
                } else {

                    let response = ajax_file(path_relative_root + 'ajax_return_address.php?order_id=' + orderId + '&return_number=' + returnNumber);

                    if (response !== '') {
                        $('.popup_numero_retour').html(response);
                        // add a title on return & return coupon popup
                        $("<div class='return_popup_title'>" + Translator.translate('effectuer_retour') + "</div><div class='recommandation'>" + Translator.translate('return_pop_print_text') + "</div>").insertBefore("#printPdf");
                    } else {
                        location.reload();
                    }
                }
            }
        }
    });
}

// Close 2nd return popup after ajax loaded
function close_popup_return(obj_elem) {
    $("#shad").hide();
    $("#popup_numero_retour_" + obj_elem).hide();
    location.reload();
}
/**
 * Validate a return for an order
 */
function createEtiquetteRetour(orderId, multiTransp) {

    let has_errors = false;
    $(".return_quantity").each(function () {
        let return_choice = $(this).closest('.js-select-motif').find('.return_choice');
        return_choice.removeClass('error');

        if ($(this).val() > 0 && return_choice.val() == '0') {
            has_errors = true;
            return_choice.addClass('error');
        }
    });

    if (!has_errors) {
        let returnNumber = ajax_file(path_relative_root + "ajax_num_etiquette.php?idOrder=" + orderId + "&multiTransp=" + multiTransp);
        showPdfEtiquetteRetour(orderId, multiTransp, returnNumber, false);
    }
}

// Check request validity of the order return
function checkValidityRequestOfReturn() {
    let selectId = $(this).attr('id');
    let selectedValue = $(this).val();

    if (selectId.includes("returnQuantity")) {
        valueIdTailleQuantity = selectId.split("returnQuantity_")[1];

        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            if (!$('#returnChoice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#returnChoice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", false);
                $('#' + selectId).addClass('choice_unlocked_both');
            }
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && !$('#returnChoice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#returnChoice_' + valueIdTailleQuantity).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#returnChoice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#returnChoice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            } else if ($('#' + selectId).hasClass('choice_locked') && $('#returnChoice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#returnChoice_' + valueIdTailleQuantity).removeClass('choice_locked');
                $('#returnChoice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", true);
                $('#returnChoice_' + valueIdTailleQuantity).val(0);
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#returnChoice_' + valueIdTailleQuantity).removeClass('choice_locked_both');
                $('#' + selectId).removeClass('choice_locked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#returnChoice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#returnChoice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            }
        }
    } else if (selectId.includes("returnChoice")) {
        valueIdTailleChoice = selectId.split("returnChoice_")[1];
        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            $('#returnQuantity_' + valueIdTailleChoice).addClass('choice_locked_both');
            $('#' + selectId).addClass('choice_locked_both');
            $('#returnQuantity_' + valueIdTailleChoice).removeClass('choice_unlocked_both');
            $('#' + selectId).removeClass('choice_unlocked_both');
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && $('#returnQuantity_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#returnQuantity_' + valueIdTailleChoice).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#returnQuantity_' + valueIdTailleChoice).removeClass('choice_locked_both');
                $('#' + selectId).removeClass('choice_locked_both');
            }
        }
    }

    if (!$('.choice_unlocked_both').length && $('.choice_locked').length) {
        $(".divBtnPrintPdf").removeClass('disabled');
    } else {
        $(".divBtnPrintPdf").addClass('disabled');
    }
}

// By default show 'sélectionner une taille' btn if no size is chosen 
function noSizeSelected() {
    if ($('#ligne_pointure .form_itm.check.size input[type="radio"]:checked').length > 0) {
        // At least one radio button is checked
        $('#btn_add_cart').val(Translator.translate('add_basket'));
        $('#btn_add_cart').removeClass('noSize');
        $('#btn_add_cart').css('opacity', '1');

    } else {
        // No radio buttons are checked
        $('#btn_add_cart').val(Translator.translate('select_size'));
        $('#btn_add_cart').addClass('noSize');
    }
}

/*
Custom Select Menu
**/
function initCustomSelect(select) {
    var x, i, j, selElmnt, a, b, c;
    if (select === undefined) {
        select = 'custom-select';
    }
    x = document.getElementsByClassName(select);
    // If the element is already customized, do not modify it
    if ($('.' + select + ' .select-items').length > 0) {
        return;
    }
    for (i = 0; i < x.length; i++) {
        selElmnt = x[i].getElementsByTagName("select")[0];
        a = document.createElement("DIV");
        a.setAttribute("class", "select-selected");
        a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        x[i].appendChild(a);
        b = document.createElement("DIV");
        b.setAttribute("class", "select-items select-hide");
        for (j = 0; j < selElmnt.length; j++) {
            if (selElmnt.options[j].value === '') {
                continue;
            }
            c = document.createElement("DIV");
            c.innerHTML = selElmnt.options[j].innerHTML;
            if (selElmnt.options[j].selected) {
                c.setAttribute("class", "same-as-selected");
            }
            c.addEventListener("click", function (e) {
                var y, i, k, s, h, l;
                s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                h = this.parentNode.previousSibling;
                l = s.length;
                for (i = 0; i < l; i++) {
                    if (s.options[i].innerHTML == this.innerHTML) {
                        s.selectedIndex = i;
                        // Triggerthe event
                        $(s).trigger('change');
                        h.innerHTML = this.innerHTML;
                        y = this.parentNode.getElementsByClassName("same-as-selected");
                        for (k = 0; k < y.length; k++) {
                            y[k].removeAttribute("class");
                        }
                        // To indicate that this element should be selected
                        this.setAttribute("class", "same-as-selected");
                        break;
                    }
                }
            });
            b.appendChild(c);
        }
        x[i].appendChild(b);
        a.addEventListener("click", function (e) {
            e.stopPropagation();
            // ereas all other select
            closeAllSelect(this);
            // Odisplay / hide the list
            this.nextSibling.classList.toggle("select-hide");
            // change arrow directyion
            this.classList.toggle("select-arrow-active");
        });
    }
}

// FP Description Accordion
function fpAccordion() {
    $(".toggleCarac").each(function () {
        $(this).click(function () {
            if ($(this).hasClass('actif')) {
                $(this).removeClass('actif');
                $(this).next().slideUp();
            } else {
                $(".toggleCarac").removeClass('actif');
                $(".toggleCarac + .content").slideUp();
                $(this).next().slideUp();
                $(this).addClass('actif');
                $(this).next().slideDown();
            }
        });
    });
}

// Used to display the promocode lightbox "specific product offered"
function display_lightbox_specifique() {
    var html = ajax_file(path_relative_root + 'ajax_lightbox_produit_specifique.php');
    if (html) {
        var $lightbox = $('#lightbox_achat_express');
        $lightbox.addClass('lightbox actif').html(
            '<div class="close_pop" id="close_lightbox_prod_specifique" onclick="closeLightboxProdSpec()"></div>' +
            '<div class="express_response" style="position: relative"></div>'
        ); $lightbox.find('.express_response').css('display', 'block').html(html);

        //Add an input for getAvailableQuantity function
        let productIdInput = $lightbox.find('.express_response #produit_id');
        productIdInput.after('<input id="produit_principal" type="hidden" name="produit_principal" value="' + productIdInput.val() + '">');

        $lightbox
            .appendTo('body')
            .show();

        var $shad = $('#shade');
        $shad
            .show().css('opacity', '1')
            .bind('click', function () {
                closeLightboxProdSpec();
                $("#alertbox").remove();
            });
    }
}

function closeLightboxProdSpec() {
    $('#lightbox_achat_express').removeClass('actif');
    $('#shade').hide();
}

function deleteFlexAlias(evt) {
    var alias = this.form;
    var alias_id = alias.alias_id.value.trim();

    var parent = $(alias.parentElement);

    evt.preventDefault();

    $.ajax({
        url: path_relative_root + "ajax_delete_alias.php",
        method: "GET",
        data: { id: alias_id },
        success: function(res) {
            if (res == 1) {
                parent.animate({ height: 0, opacity: 0 }, 350, function() {
                    parent.remove();
                });
            }
        }
    });

    return false;
}